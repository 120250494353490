<template>
  <el-dialog
    custom-class="ql-dialog fit-width-content"
    :title="isEdit ? '编辑任教' : '新增任教'"
    :visible.sync="visible"
  >
    <el-form ref="form" :model="formData" :rules="rules" label-width="auto">
      <el-form-item label="年级" prop="gradeId" v-if="!isEdit" size="small">
        <el-select
          v-model="formData.gradeId"
          placeholder="请选择年级"
          @change="handleDialogGradeChange"
          style="width: 400px"
        >
          <el-option v-for="item in gradeList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item label="班级" prop="classId" size="small">
        <el-select v-model="formData.classId" placeholder="请选择班级" style="width: 400px" :disabled="isEdit">
          <el-option v-for="item in classList" :key="item.classId" :label="item.className" :value="item.classId" />
        </el-select>
      </el-form-item>

      <el-form-item label="学科" prop="lessonId" size="small">
        <el-select v-model="formData.lessonId" placeholder="请选择年级学科" style="width: 400px" :disabled="isEdit">
          <el-option v-for="item in lessonList" :key="item.value" :label="item.text" :value="item.value" />
        </el-select>
      </el-form-item>

      <el-form-item label="教师名字" prop="teacherId" size="small">
        <el-select
          v-model="formData.teacherId"
          remote
          :remote-method="remoteMethod"
          filterable
          placeholder="请输入教师姓名关键字检索"
          style="width: 400px"
        >
          <el-option
            v-for="item in options"
            :key="item.userId"
            :label="item.name + ' ' + (item.idNumber || '')"
            :value="item.userId"
          />
        </el-select>
      </el-form-item>
    </el-form>

    <span slot="footer">
      <el-button @click="handleCancel" size="small">取消</el-button>
      <el-button type="primary" v-debounceClick="handleSave" :loading="loading" size="small">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { Button, Dialog, Input, Form, FormItem, Select, Option } from 'element-ui';
import { getTeacherTable, addTeach, editTeach, getLessonList, getClassList } from '@/api/basic';

export default {
  name: 'TeachDialog',

  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    dialogData: Object,
    gradeList: Array,
    teacherList: Array,
  },

  data() {
    const validateGradeId = (rule, value, callback) => {
      if (value !== null && !value) {
        callback(new Error('请选择年级'));
        return;
      }
      callback();
    };

    const validateClassId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择班级'));
        return;
      }
      callback();
    };

    const validateSubjectId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择学科'));
        return;
      }
      callback();
    };

    const validateTeacherId = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择教师姓名'));
        return;
      }
      callback();
    };

    return {
      loading: false,
      formData: {
        gradeId: '',
        classId: '',
        lessonId: '',
        teacherId: '',
      },
      options: [],
      rules: {
        gradeId: [
          {
            required: true,
            validator: validateGradeId,
            trigger: ['blur', 'change'],
          },
        ],
        classId: [
          {
            required: true,
            validator: validateClassId,
            trigger: ['blur', 'change'],
          },
        ],
        lessonId: [
          {
            required: true,
            validator: validateSubjectId,
            trigger: ['blur', 'change'],
          },
        ],
        teacherId: [
          {
            required: true,
            validator: validateTeacherId,
            trigger: ['blur', 'change'],
          },
        ],
      },

      periodId: '', // 年级对应的学段
      lessonList: [],
      classList: [],
    };
  },

  methods: {
    // 获取学科列表
    getLessonList(periodId) {
      getLessonList({
        status: 0,
        periodId,
      }).then((res) => {
        if (res.status === 0) {
          this.lessonList = res.result
            .reduce((prev, cur) => {
              prev.push(...cur.basicSchLessonDTOList);
              return prev;
            }, [])
            .map((item) => ({ text: item.lessonName, value: item.lessonId }));
        }
      });
    },

    handleSave() {
      this.loading = true;

      this.$refs.form.validate(async (valid) => {
        if (valid) {
          let res;
          if (this.formData.id) {
            res = await editTeach({
              id: this.formData.id,
              teacherId: this.formData.teacherId,
            }).catch(() => (this.loading = false));
          } else {
            res = await addTeach({
              gradeId: this.formData.gradeId,
              classId: this.formData.classId,
              lessonId: this.formData.lessonId,
              teacherId: this.formData.teacherId,
            }).catch(() => (this.loading = false));
          }

          if (res.status === 0) {
            this.$message.success(this.isEdit ? '编辑任教成功' : '新增任教成功');
            this.$emit('success', this.formData);
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      });
    },

    handleCancel() {
      this.visible = false;
      this.$refs.form.clearValidate();
    },

    // 年级改变
    handleDialogGradeChange(id) {
      this.formData.classId = '';

      const periodId = this.gradeList.find((item) => item.value === id)?.periodId || '';
      if (periodId !== this.periodId) {
        this.periodId = periodId;
        this.formData.lessonId = '';
      }

      this.getLessonList(periodId);
      this.getClassListData(id);
    },

    // 获取年级里的班级
    getClassListData(id) {
      getClassList({
        gradeIds: [id],
        pageSize: 99999,
        schoolYearId: this.$route.query.schoolYearId,
      }).then((res) => {
        if (res.status === 0) {
          this.classList = res.result.data.map((item) => ({
            ...item,
            className: `${item.gradeName}${item.className}`,
          }));
        }
      });
    },

    remoteMethod(query) {
      query = query ? query.replace(/ /g, '') : '';

      if (query) {
        this.loading = true;
        getTeacherTable({
          commonFilter: query,
          upgradePlanId: this.$route.query.upgradePlanId,
          pageSize: 99999,
        }).then((res) => {
          this.loading = false;
          this.options = res.result.data;
        });
      } else {
        this.options = this.teacherList;
      }
    },

    clearValid() {
      this.$nextTick(() => {
        this.$refs['form'].clearValidate();
      });
    },
  },

  computed: {
    visible: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      },
    },
  },

  watch: {
    visible(val) {
      if (val) {
        this.remoteMethod();
        this.formData = this.dialogData;
        this.clearValid();
        this.loading = false;

        const { gradeId } = this.formData;

        if (this.formData.gradeId) {
          this.getClassListData(gradeId);

          const periodId = this.gradeList.find((item) => item.value === val)?.periodId || '';
          this.periodId = periodId;
          this.getLessonList(periodId);
        }
      } else {
        this.lessonList = [];
        this.classList = [];
        this.periodId = '';
      }
    },
  },
};
</script>
